var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/tab-for-a-cause-360";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://63bb5339a4da48058df274d49f9929c8@o359384.ingest.sentry.io/5848221",
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== "production") {
      console.error(event);
      return null;
    } else {
      return event;
    }
  },
  integrations: [
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
